import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bienvenidos-al-manual-de-ecomerciar"
    }}>{`Bienvenidos al Manual de Ecomerciar`}</h1>
    <p>{`Manual de ecomerciar - Powered by Gatsby`}</p>
    <p>{`Acá va todo lo meta`}</p>
    <h2 {...{
      "id": "como-editar-o-agregar-contenido---consola"
    }}>{`Como editar o agregar contenido - Consola`}</h2>
    <p>{`Hay dos formas básicamente, desde gitlab, o desde nuestra pc.`}</p>
    <p>{`Desde nuestra pc primero tenemos que bajar el repositorio.`}</p>
    <p>{`Para eso tenemos que hacer primero un access token. `}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Acceder a gitlab`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Arriba a la derecha ir a Settings.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Entrar en Access Tokens.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Crear un access token con todos los permisos va a ser algo asi : Y99sg8JyBsWongmFVHGi`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Clonar el repositorio desde la terminal, debemos tener GIT instalado.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Bajar GIT segun el sistema operativo que tengas.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Meter el siguiente comando en la consola remplazando token por nuestro token`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://oauth2:token@gitlab.com/ecomerciar-projects/manual-ecomerciar.git manual
`}</code></pre>
    <p>{`asi:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git clone https://oauth2:Y99sg8JyBsWongmFVHGi@gitlab.com/ecomerciar-projects/manual-ecomerciar.git manual

`}</code></pre>
    <p>{`Esto va a crear una carpeta manual, con todos los contenidos adentro.`}</p>
    <p>{`Ya tenes el manual en tu PC, podes correrlo con Yarn, o Npm.`}</p>
    <p>{`Primero hay que tener NODE instalado, y darle NPM install para instalar todos los paquetes, o Yarn install.`}</p>
    <h2 {...{
      "id": "como-editar-o-agregar-contenido---desde-gitlab"
    }}>{`Como editar o agregar contenido - Desde Gitlab`}</h2>
    <ol>
      <li parentName="ol">{`Desde el repositorio abierto apretar WEB ide`}</li>
      <li parentName="ol">{`Desde aquí podemos editar todos los archivos desde el editor gitlab.`}</li>
      <li parentName="ol">{`Para crear un nuevo titulo dentro de los links del manual a la izq hay que crear un nuevo archivo .md dentro de la carpeta docs.`}</li>
      <li parentName="ol">{`Hay que respetar el formato de MarkDown.`}</li>
      <li parentName="ol">{`Además tenemos que meter lo siguiente en los archivos arriba del todo- Esto es llamado como FrontMatter. `}</li>
    </ol>
    <p>{`Ejemplo con el archivo de Backups`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`
---
name: Backups
route: /backups
---

`}</code></pre>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">
        <p parentName="li">{`Una vez que terminamos de hacer las ediciones y creaciones de archivos, le damos a COMMIT.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Es importante hacer esto una vez que estamos seguros de que los cambios esten bien.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Esperá... Automaticamente se van a subir los cambios al manual. Se va hacer un build y se va a subir a netlify. `}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      